import React, { useState, useContext } from "react"
import SoundContext from "../state/SoundContext"
import { Container } from "semantic-ui-react"
import ReactPlayer from "react-player"
import styles from "./services.module.scss"
import { Helmet } from "react-helmet"
import useWindowSize from "../utilities/useWindowSize"
import LocalisedLink from "../components/LocalisedLink"

import itMessages from "../i18n/it.json"
import enMessages from "../i18n/en.json"

const messages = {
  it: itMessages,
  en: enMessages,
}

function Services(props) {
  const { locale } = props.pageContext

  const [videoPlaying1, setVideoPlaying1] = useState(false)
  const [videoPlaying2, setVideoPlaying2] = useState(false)
  const [videoPlaying3, setVideoPlaying3] = useState(false)

  const [videoPlaying4, setVideoPlaying4] = useState(false)
  const [videoPlaying5, setVideoPlaying5] = useState(false)
  const [videoPlaying6, setVideoPlaying6] = useState(false)

  const [videoPlaying7, setVideoPlaying7] = useState(false)
  const [videoPlaying8, setVideoPlaying8] = useState(false)
  const [videoPlaying9, setVideoPlaying9] = useState(false)

  const [isMuted, setIsMuted] = useContext(SoundContext)

  const size = useWindowSize()

  return (
    <>
      <Helmet>
        <title>
          {locale === "en" ? "Services - Amplitudo" : "Servizi - Amplitudo"}
        </title>
        <meta
          name="description"
          content={
            locale === "en"
              ? "Our services include sound design, soundtrack music composition, 3D audio, podcast, foley sessions, editing, location sound, studio recording, dubbing, voice-over, mixing, master."
              : "I nostri servizi includono sound design, soundtrack e composizione musicale, audio 3D, podcast, foley, editing, registrazione in presa diretta, registrazione in studio, doppiaggio, speakeraggio, voice-over, mix e master."
          }
        />
      </Helmet>
      <div className="services-page">
        <div className={styles.servicesContainer}>
          <div className="centered-title-container">
            <div>
              <h1 className="centered-title">{messages[locale].services}</h1>
              <div className="centered-title-background"></div>
            </div>
          </div>

          <Container>
            <div className="section-content-container section-container-smaller who-section">
              <div className="section-column">
                <h2 className="centered-title section-title-services">
                  {messages[locale].whoTitle}
                </h2>
                {locale === "en" ? (
                  <div className="section-content-container section-inner-container-smaller">
                    <div className="section-column section-smaller service-descriptions">
                      We are based in beautiful Turin, Italy, but we think and
                      work globally. Our studio is filled with professionals
                      specialised in every aspect of sound production.
                    </div>
                    <div className="section-column section-smaller service-descriptions">
                      We work with our minds, hands and ears to deliver
                      top-notch sonic solutions from the bottom up: client
                      analysis, creative strategy, production and delivery.
                    </div>
                  </div>
                ) : (
                  <div className="section-content-container section-inner-container-smaller">
                    <div className="section-column section-smaller service-descriptions">
                      La nostra sede è nella splendida Torino, ma pensiamo e
                      lavoriamo a livello globale. Siamo uno studio di
                      professionisti specializzati in ogni aspetto della
                      produzione audio.
                    </div>
                    <div className="section-column section-smaller service-descriptions">
                      Lavoriamo con le nostre menti, mani e orecchie per fornire
                      eccellenti soluzioni sonore dalla A alla Z: analisi del
                      cliente, strategia creativa, produzione e consegna finale.
                    </div>
                  </div>
                )}
              </div>
              <div className="section-column">
                <div className="video-fragments-container">
                  <div className="video-fragment video-one-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/italica1.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying1(true)}
                        onMouseLeave={() => setVideoPlaying1(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/3_italica1.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying1}
                          onEnded={() => setVideoPlaying1(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-two-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/italica2.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying2(true)}
                        onMouseLeave={() => setVideoPlaying2(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/3_italica2.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying2}
                          onEnded={() => setVideoPlaying2(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-three-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/italica3.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying3(true)}
                        onMouseLeave={() => setVideoPlaying3(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/3_italica3.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying3}
                          onEnded={() => setVideoPlaying3(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <div className="claims-background claims-background-gold">
            <Container>
              <h2 className="claims claims-sx">
                {messages[locale].servicesStatement3}
                <br />
                {messages[locale].servicesStatement4}
              </h2>
            </Container>
          </div>

          {/* <div className={styles.servicesStatementContainer}>
          <div className={styles.servicesSubStatementContainer}>
            <h2 className="statement-centered statement-first-line statement-bigger">
              {messages[locale].servicesStatement1}
            </h2>
            <h2 className="statement-centered statement-second-line statement-bigger">
              {messages[locale].servicesStatement2}
            </h2>
          </div>
          <div className={styles.servicesSubStatementContainer}>
            <h2 className="statement-centered statement-first-line statement-bigger">
              {messages[locale].servicesStatement3}
            </h2>
            <h2 className="statement-centered statement-second-line statement-bigger">
              {messages[locale].servicesStatement4}
            </h2>
          </div>
        </div> */}

          <Container className={styles.whatSection}>
            <div className="swap-col-order">
              <div className="section-content-container section-container-smaller">
                <div className="section-column">
                  <div className="video-fragments-container">
                    <div className="video-fragment video-one-container">
                      {size.width <= 768 ? (
                        <img
                          className="video-replacement"
                          src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/cerulisina1.png"
                          alt="video example"
                        />
                      ) : (
                        <div
                          className="video-constraints"
                          onMouseOver={() => setVideoPlaying4(true)}
                          onMouseLeave={() => setVideoPlaying4(false)}
                        >
                          <ReactPlayer
                            className="video"
                            url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/4_thok1.mp4"
                            width="100%"
                            height="100%"
                            playing={videoPlaying4}
                            onEnded={() => setVideoPlaying4(false)}
                            muted={isMuted}
                          />
                        </div>
                      )}
                    </div>
                    <div className="video-fragment video-two-container">
                      {size.width <= 768 ? (
                        <img
                          className="video-replacement"
                          src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/cerulisina2.png"
                          alt="video example"
                        />
                      ) : (
                        <div
                          className="video-constraints"
                          onMouseOver={() => setVideoPlaying5(true)}
                          onMouseLeave={() => setVideoPlaying5(false)}
                        >
                          <ReactPlayer
                            className="video"
                            url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/4_thok2.mp4"
                            width="100%"
                            height="100%"
                            playing={videoPlaying5}
                            onEnded={() => setVideoPlaying5(false)}
                            muted={isMuted}
                          />
                        </div>
                      )}
                    </div>
                    <div className="video-fragment video-three-container">
                      {size.width <= 768 ? (
                        <img
                          className="video-replacement"
                          src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/cerulisina3.png"
                          alt="video example"
                        />
                      ) : (
                        <div
                          className="video-constraints"
                          onMouseOver={() => setVideoPlaying6(true)}
                          onMouseLeave={() => setVideoPlaying6(false)}
                        >
                          <ReactPlayer
                            className="video"
                            url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/4_thok3.mp4"
                            width="100%"
                            height="100%"
                            playing={videoPlaying6}
                            onEnded={() => setVideoPlaying6(false)}
                            muted={isMuted}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section-column">
                  <h2 className="centered-title section-title-services">
                    {messages[locale].whatTitle}
                  </h2>
                  {locale === "en" ? (
                    <div className="section-content-container section-inner-container-smaller no-col-swap">
                      <div className="section-column section-smaller service-descriptions">
                        We support animation studios, creative agencies and
                        directors with tailor-made music and sounds.
                      </div>
                      <div className="section-column section-smaller service-descriptions">
                        Original compositions, sound identities, audio logos,
                        immersive soundscapes and much more.
                      </div>
                    </div>
                  ) : (
                    <div className="section-content-container section-inner-container-smaller no-col-swap">
                      <div className="section-column section-smaller service-descriptions">
                        Affianchiamo studi di animazione, agenzie creative e
                        registi con musiche e suoni originali.
                      </div>
                      <div className="section-column section-smaller service-descriptions">
                        Composizioni e jingle, identità sonore, audio loghi,
                        paesaggi sonori immersivi e molto altro.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>

          <Container>
            <div className={styles.servicesDetailsContainer}>
              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle1}
                  </h3>
                  <p>{messages[locale].serviceDescription1}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle2}
                  </h3>
                  <p>{messages[locale].serviceDescription2}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle3}
                  </h3>
                  <p>{messages[locale].serviceDescription3}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle4}
                  </h3>
                  <p>{messages[locale].serviceDescription4}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle5}
                  </h3>
                  <p>{messages[locale].serviceDescription5}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle6}
                  </h3>
                  <p>{messages[locale].serviceDescription6}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle7}
                  </h3>
                  <p>{messages[locale].serviceDescription7}</p>
                </div>
              </div>

              <div className={styles.servicesSingleDetailContainer}>
                <div className={styles.servicesDetail}>
                  <h3 className={styles.servicesDetailTitle}>
                    {messages[locale].serviceTitle8}
                  </h3>
                  <p>{messages[locale].serviceDescription8}</p>
                </div>
              </div>

              <div
                className={`${styles.servicesSingleDetailContainer} ${styles.ctaContainer}`}
              >
                <LocalisedLink
                  to="/contacts"
                  locale={locale}
                  className={styles.textLink}
                >
                  <button className="button-primary">
                    {messages[locale].contactUs}
                  </button>
                </LocalisedLink>
              </div>
            </div>
          </Container>

          {/* <div className={styles.servicesStatementContainer}>
        <div className={styles.servicesSubStatementContainer}>
          <h2 className="statement-centered statement-first-line statement-bigger">
            {messages[locale].servicesStatement5}
          </h2>
          <h2 className="statement-centered statement-second-line statement-bigger">
            {messages[locale].servicesStatement6}
          </h2>
        </div>
        <div className={styles.servicesSubStatementContainer}>
          <h2 className="statement-centered statement-first-line statement-bigger">
            {messages[locale].servicesStatement7}
          </h2>
          <h2 className="statement-centered statement-second-line statement-bigger">
            {messages[locale].servicesStatement8}
          </h2>
        </div>
      </div> */}

          <Container>
            <div className="section-content-container section-container-smaller">
              <div className="section-column">
                <h2 className="centered-title section-title-services">
                  {messages[locale].howTitle}
                </h2>
                {locale === "en" ? (
                  <div className="section-content-container section-inner-container-smaller">
                    <div className="section-column section-smaller service-descriptions">
                      We combine the Italian heritage and tradition of design
                      with our sound production skills to come up with the best
                      creative solutions.
                    </div>
                    <div className="section-column section-smaller service-descriptions">
                      Whether you are looking for your new audio branding or the
                      perfect sound for your contents, we got you covered. Just
                      drop us a line!
                    </div>
                  </div>
                ) : (
                  <div className="section-content-container section-inner-container-smaller">
                    <div className="section-column section-smaller service-descriptions">
                      Uniamo l’arte del design con le nostre capacità di
                      produzione audio per trovare le migliori soluzioni
                      creative.
                    </div>
                    <div className="section-column section-smaller service-descriptions">
                      Che tu stia cercando la tua nuova identità audio o il
                      suono perfetto per i tuoi contenuti, ci pensiamo noi.
                      Scrivici!
                    </div>
                  </div>
                )}
              </div>
              <div className="section-column">
                <div className="video-fragments-container">
                  <div className="video-fragment video-one-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/ubi1.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying7(true)}
                        onMouseLeave={() => setVideoPlaying7(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/5_costadoro1.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying7}
                          onEnded={() => setVideoPlaying7(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-two-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/ubi2.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying8(true)}
                        onMouseLeave={() => setVideoPlaying8(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/5_costadoro2.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying8}
                          onEnded={() => setVideoPlaying8(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                  <div className="video-fragment video-three-container">
                    {size.width <= 768 ? (
                      <img
                        className="video-replacement"
                        src="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/mobile-versions/ubi3.png"
                        alt="video example"
                      />
                    ) : (
                      <div
                        className="video-constraints"
                        onMouseOver={() => setVideoPlaying9(true)}
                        onMouseLeave={() => setVideoPlaying9(false)}
                      >
                        <ReactPlayer
                          className="video"
                          url="https://amplitudo-resources.s3.eu-west-3.amazonaws.com/hd-videos/video-snippets/5_costadoro3.mp4"
                          width="100%"
                          height="100%"
                          playing={videoPlaying9}
                          onEnded={() => setVideoPlaying9(false)}
                          muted={isMuted}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Services
